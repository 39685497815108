/**
 * Section Navigation display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if Section Navigation is present
 */

const sectionNav = (() => {
  function render() {
    const bttn = $('.section-nav__toggle');

    bttn.on('click', (e) => {
      const dropdown = $(e.target).next();
      const hero = e.target.closest('.hero');

      hero.classList.toggle('js-nav-expanded');

      if (e.target.getAttribute('aria-expanded') === 'false') {
        e.target.setAttribute('aria-expanded', 'true');
        dropdown.attr('aria-hidden', 'false');
        dropdown.slideDown(300);
      } else {
        e.target.setAttribute('aria-expanded', 'false');
        dropdown.attr('aria-hidden', 'true');
        dropdown.slideUp(300);
        hero.classList.add('js-nav-collapsing');
      }

      if (hero.classList.contains('js-nav-collapsing')) {
        setTimeout(() => {
          hero.classList.remove('js-nav-collapsing');
        }, 300);
      }
    });
  }

  function ensureProperAttributesSet() {
    // removing aria-hidden and display: none from desktop variant of section nav
    const desktopAsideContainer = document.querySelectorAll(
      '.container-aside--desktop',
    );
    const [desktopSectionNavDropdown] = document.querySelectorAll(
      '.container-aside--desktop .section-nav__dropdown',
    );

    if (desktopAsideContainer.length) {
      desktopSectionNavDropdown.removeAttribute('style');
      desktopSectionNavDropdown.removeAttribute('aria-hidden');
    }
  }

  const init = () => {
    if (!$('.section-nav').length) {
      return;
    }

    render();
    $(window).on('load', ensureProperAttributesSet);
  };

  return {
    init,
  };
})();

export default sectionNav;
