/**
 * Pathway Listing display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if Pathway Listing is present
 */

const pathwayExplore = (() => {
  function render() {
    const links = $('.pathway-explore__link');

    links.on('click', (e) => {
      const $targetId = $(e.target).attr('data-link');

      $('html, body').animate(
        {
          scrollTop: $(`#${$targetId}`).offset().top,
        },
        300,
        () => {
          $(`#${$targetId}`).parent().find('a').focus();
        },
      );
    });

    document.querySelectorAll('.pathway-explore__link').forEach((elem) => {
      elem.addEventListener('mousemove', (e) => {
        const rect = document
          .querySelector('.pathway-explore')
          .getBoundingClientRect();
        const x = e.clientX - rect.left; // x position within the element.
        const y = e.clientY - rect.top; // y position within the element.

        const circle = document.getElementById('pathway-explore__cursor');
        circle.classList.add('js-show');

        circle.style.left = `${x}px`;
        circle.style.top = `${y}px`;
      });

      elem.addEventListener('mouseleave', (e) => {
        document
          .getElementById('pathway-explore__cursor')
          .classList.remove('js-show');
      });
    });
  }

  const init = () => {
    if (!$('.pathway-explore').length) {
      return;
    }

    render();
  };

  return {
    init,
  };
})();

export default pathwayExplore;
