/**
 * Footer Sock display object interactions and accessibility
 * @param {*} $
 * @returns {init} Determine and run if footer sock present
 */

const footer = (() => {
  // Scrolls user to top of page
  function scrollBackToTop() {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });

    setTimeout(() => {
      document.querySelectorAll('.skip-link')[0].focus();
    }, 500);
  }

  const init = () => {
    $('.footer__back-to-top').on('click', scrollBackToTop);
    $('.footer-sock__back-to-top').on('click', scrollBackToTop);
  };

  return {
    init,
  };
})();

export default footer;
