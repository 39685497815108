const lastWord = (() => {
  const addLastWordSpan = (selectors, timeout) => {
    setTimeout(() => {
      $(selectors).each((i, elem) => {
        // eslint-disable-next-line max-nested-callbacks
        $(elem).html(function () {
          // separate the text by spaces
          const $this = $.trim($(this).text());
          const text = $this.split(' ');
          // drop the last word and store it in a variable
          const last = text.pop();
          // join the text back and if it has more than 1 word add the span tag
          // to the last word
          return `${text.join(
            ' ',
          )} <span class="last-word">${last}<span></span></span>`;
        });
      });
    }, timeout);
  };

  const init = () => {
    const selectors =
      '.cta, .column__title a, .genimglist__title a, .events3up__title a, .stat3up__cta a';

    $(document).ready(function () {
      // adds last word spans to any of the 'selectors' elements site-wide
      addLastWordSpan(selectors, 200);
    });
  };

  return {
    init,
    addLastWordSpan,
  };
})();

export default lastWord;
