/**
 * Path Selector display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if path selector is present
 */

const pathSelector = (() => {
  const pathCards = document.querySelectorAll('.path-card');

  function closeDialog() {
    const [activeDialog] = document.querySelectorAll('.dialog.js-active');
    const dialogID = activeDialog.id;
    const [button] = document.querySelectorAll(
      `.path-card__toggle[data-dialog="${dialogID}"]`,
    );

    activeDialog.classList.remove('js-active');

    pathCards.forEach((pathCard) => {
      pathCard.removeAttribute('style');
    });

    button.focus();
  }

  function overlayKeyboardNavigation() {
    document.addEventListener('keydown', (e) => {
      const isEscapePressed = e.key === 'Escape' || e.keyCode === 27;

      if (!isEscapePressed) {
        return;
      }

      if (
        document.querySelectorAll('.path-selector .dialog.js-active').length
      ) {
        const [activeDialog] = document.querySelectorAll(
          '.path-selector .dialog.js-active',
        );
        const dialogID = activeDialog.id;
        const [button] = document.querySelectorAll(
          `.path-card__toggle[data-dialog="${dialogID}"]`,
        );

        closeDialog();

        button.focus();
      }
    });
  }

  function togglePathCard(e) {
    const clicked = e.target;

    if (window.innerWidth < 1025) {
      const pathCard = clicked.parentElement;
      const [pathCardContent] = pathCard.querySelectorAll(
        '.path-card__content',
      );

      pathCard.classList.toggle('js-expanded');

      if (pathCard.classList.contains('js-expanded')) {
        clicked.setAttribute('aria-expanded', 'true');
      } else {
        clicked.setAttribute('aria-expanded', 'false');
      }

      $(pathCardContent).slideToggle(300);
    } else {
      const dialogSelector = clicked.dataset.dialog;
      const dialog = document.getElementById(dialogSelector);
      const [dialogClose] = dialog.querySelectorAll('.dialog__close');

      dialog.classList.toggle('js-active');

      if (dialog.classList.contains('js-active')) {
        pathCards.forEach((pathCard) => {
          pathCard.style.display = 'none';
        });

        dialogClose.focus();

        overlayKeyboardNavigation();
      }
    }
  }

  function ensurePathCardsAreClosed() {
    if (window.innerWidth <= 1024) {
      return;
    }

    pathCards.forEach((pathCard) => {
      const [toggleButton] = pathCard.querySelectorAll('.path-card__toggle');
      const pathCardContent = toggleButton.nextElementSibling;

      if (pathCard.classList.contains('js-expanded')) {
        pathCard.classList.remove('js-expanded');
        toggleButton.setAttribute('aria-expanded', 'false');
        pathCardContent.removeAttribute('style');
      }
    });
  }

  const init = () => {
    if (!$('.path-selector').length) {
      return;
    }

    $('.path-card__toggle').on('click', togglePathCard);
    $('.path-selector .dialog__close').on('click', closeDialog);
    window.onresize = ensurePathCardsAreClosed;
  };

  return {
    init,
  };
})();

export default pathSelector;
