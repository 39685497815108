/**
 * Header interactions and accessibility
 * @param {*} $
 * @returns {init}
 */

const header = (() => {
  const html = document.querySelectorAll('html')[0];
  const body = document.querySelectorAll('body')[0];
  const mainHeader = document.querySelectorAll('.header')[0];
  const primaryNav = mainHeader.querySelectorAll('.primary-nav')[0];
  const primaryNavToggleButton = mainHeader.querySelectorAll(
    '.primary-nav__toggle',
  )[0];
  const search = mainHeader.querySelectorAll('.search')[0];
  const keysPressed = [];

  function closePrimaryNav() {
    mainHeader.classList.remove('js-active-nav');
    html.classList.remove('js-fixed');
    body.classList.remove('js-fixed');
    primaryNavToggleButton.setAttribute('aria-expanded', 'false');
    primaryNavToggleButton.querySelectorAll('.visually-hidden')[0].innerHTML =
      'Open Menu';
  }

  function primaryNavKeyboardNavigation() {
    document.addEventListener('keydown', (e) => {
      const firstFocusableElement = mainHeader.querySelectorAll(
        '.primary-nav__toggle',
      )[0];
      const focused = document.activeElement;
      const isTabPressed = e.key === 'Tab' || e.keyCode === 9;
      const isEscapePressed = e.key === 'Escape' || e.keyCode === 27;
      const allFocusableElements = primaryNav.querySelectorAll('a, button');
      const lastFocusableElement =
        allFocusableElements[allFocusableElements.length - 1];

      if (!mainHeader.classList.contains('js-active-nav')) {
        return;
      }

      if (isEscapePressed) {
        closePrimaryNav();
      }

      if (!isTabPressed) {
        return;
      }

      if (e.shiftKey) {
        // shift + tab was pressed - looping through items backwards
        if (focused === firstFocusableElement) {
          lastFocusableElement.focus();
          e.preventDefault();
        }
      } else {
        // tab was pressed - looping through items forwards
        // eslint-disable-next-line no-lonely-if
        if (focused === lastFocusableElement) {
          firstFocusableElement.focus();
          e.preventDefault();
        }
      }
    });
  }

  function togglePrimaryNav() {
    const [toggleButtonHiddenText] =
      primaryNavToggleButton.querySelectorAll('.visually-hidden');

    mainHeader.classList.toggle('js-active-nav');

    if (mainHeader.classList.contains('js-active-nav')) {
      html.classList.add('js-fixed');
      body.classList.add('js-fixed');
      primaryNavToggleButton.setAttribute('aria-expanded', 'true');
      toggleButtonHiddenText.innerHTML = 'Close Menu';

      primaryNavKeyboardNavigation();
    } else {
      html.classList.remove('js-fixed');
      body.classList.remove('js-fixed');
      primaryNavToggleButton.setAttribute('aria-expanded', 'false');
      toggleButtonHiddenText.innerHTML = 'Open Menu';
    }
  }

  function togglePrimaryNavSubmenu(e) {
    const clicked = e.target;
    const submenu = clicked.nextElementSibling;
    const parent = submenu.parentElement;
    const [visuallyHiddenText] = clicked.querySelectorAll('.visually-hidden');

    parent.classList.toggle('js-expanded');

    if (parent.classList.contains('js-expanded')) {
      clicked.setAttribute('aria-expanded', 'true');
      visuallyHiddenText.innerHTML = 'Collapse Submenu';
    } else {
      clicked.setAttribute('aria-expanded', 'false');
      visuallyHiddenText.innerHTML = 'Expand Submenu';
    }

    $(submenu).slideToggle(300);
  }

  function desktopSubmenuKeyboardNavigation() {
    document.addEventListener('keydown', (e) => {
      const focused = e.target;
      const submenu = focused.parentElement.parentElement;
      const isEscapePressed = e.key === 'Escape' || e.keyCode === 27;

      if (isEscapePressed) {
        let toggleButton;

        if (focused.classList.contains('header__submenu-toggle')) {
          toggleButton = focused;
        } else if (focused.classList.contains('header__main-item-link')) {
          toggleButton = focused.nextElementSibling;
        } else {
          toggleButton =
            focused.parentElement.parentElement.previousElementSibling;
        }

        if (toggleButton.parentElement.classList.contains('js-expanded')) {
          toggleButton.click();
          toggleButton.previousElementSibling.focus();
        }
      }

      if (submenu.classList.contains('header__submenu')) {
        const lastMenuItem = submenu.querySelectorAll('li:last-of-type > a')[0];
        const submenuToggleButton = submenu.previousElementSibling;
        const submenuParentLink =
          submenu.previousElementSibling.previousElementSibling;

        if (
          focused === lastMenuItem &&
          submenuToggleButton.getAttribute('aria-expanded') === 'true'
        ) {
          if (keysPressed.includes('Tab') && !keysPressed.includes('Shift')) {
            submenuToggleButton.click();
          }
        }
      } else if (focused.classList.contains('header__main-item-link')) {
        const submenuToggleButton = focused.nextElementSibling;

        if (submenuToggleButton.getAttribute('aria-expanded') === 'true') {
          if (keysPressed.includes('Tab') && keysPressed.includes('Shift')) {
            submenuToggleButton.click();
          }
        }
      }
    });
  }

  function toggleMainHeaderSubmenu(e) {
    const clicked = e.target;
    const submenu = clicked.nextElementSibling;
    const parent = submenu.parentElement;
    const [visuallyHiddenText] = clicked.querySelectorAll('.visually-hidden');

    if (parent.classList.contains('js-disabled')) {
      parent.classList.remove('js-disabled');
    }

    parent.classList.toggle('js-expanded');

    if (parent.classList.contains('js-expanded')) {
      clicked.setAttribute('aria-expanded', 'true');
      visuallyHiddenText.innerHTML = 'Collapse Submenu';

      desktopSubmenuKeyboardNavigation();
    } else {
      clicked.setAttribute('aria-expanded', 'false');
      visuallyHiddenText.innerHTML = 'Expand Submenu';
    }
  }

  function setDesktopSubmenuHeight() {
    if (window.innerWidth < 1025) {
      return;
    }

    const mainNavItems = mainHeader.querySelectorAll('.header__main-item');

    mainNavItems.forEach((navItem) => {
      const submenu = navItem.querySelectorAll('.header__submenu')[0];
      let submenuHeight = 0;

      navItem.classList.add('js-expanded');

      submenu.style.cssText =
        'width: 100vw; height: auto; max-height: unset !important; transition: unset;';

      submenuHeight = submenu.offsetHeight;
      submenuHeight += 110; // 110 is the sum of top and bottom submenu padding

      navItem.classList.remove('js-expanded');
      submenu.removeAttribute('style');

      submenu.style.maxHeight = `${submenuHeight}px`;
    });
  }

  function stickyHeaderScroll() {
    let lastScrollTop = window.pageYOffset;
    const headerOffsetTop = mainHeader.offsetTop;

    document.addEventListener('scroll', function () {
      // header should NOT be sticky when scrolling down
      if (window.pageYOffset > lastScrollTop) {
        // user scrolling down
        setTimeout(() => {
          mainHeader.classList.remove('js-sticky');
        }, 300);
      } else {
        // user scrolling up
        mainHeader.classList.add('js-sticky');
      }

      // if the user is at the top of the page, we will remove the fixed positioning from the header
      if (window.pageYOffset <= 0) {
        mainHeader.classList.remove('js-sticky');
      }

      if (
        window.innerWidth < 700 &&
        window.pageYOffset <= 50 + headerOffsetTop
      ) {
        mainHeader.classList.remove('js-hide');
      } else if (
        window.innerWidth < 1025 &&
        window.pageYOffset <= 83 + headerOffsetTop
      ) {
        mainHeader.classList.remove('js-hide');
      } else if (
        window.innerWidth >= 1025 &&
        window.pageYOffset <= 139 + headerOffsetTop
      ) {
        mainHeader.classList.remove('js-hide');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (window.pageYOffset > lastScrollTop) {
          // user scrolling down
          mainHeader.classList.add('js-hide');
        } else {
          // user scrolling up
          mainHeader.classList.remove('js-hide');
        }
      }

      const scrollTop = window.pageYOffset;
      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    });
  }

  function searchFocusLoop(firstSelector, lastSelector) {
    document.addEventListener('keydown', (e) => {
      const focused = document.activeElement;
      const isTabPressed = e.key === 'Tab' || e.keyCode === 9;
      const firstFocusableElement =
        mainHeader.querySelectorAll(firstSelector)[0];
      const lastFocusableElement = mainHeader.querySelectorAll(lastSelector)[0];

      if (!mainHeader.classList.contains('js-active-search')) {
        return;
      }

      if (!isTabPressed) {
        return;
      }

      if (e.shiftKey) {
        // shift + tab was pressed - looping through items backwards
        if (focused === firstFocusableElement) {
          lastFocusableElement.focus();
          e.preventDefault();
        }
      } else {
        // tab was pressed - looping through items forwards
        // eslint-disable-next-line no-lonely-if
        if (focused === lastFocusableElement) {
          firstFocusableElement.focus();
          e.preventDefault();
        }
      }
    });
  }

  function toggleSearch() {
    let searchInput;
    let searchToggleButton;

    if (window.innerWidth < 1025) {
      [searchInput] = mainHeader.querySelectorAll('#search__input--mobile');
      [searchToggleButton] = mainHeader.querySelectorAll(
        '.header--mobile .search__toggle',
      );
    } else {
      [searchInput] = mainHeader.querySelectorAll('#search__input--desktop');
      [searchToggleButton] = mainHeader.querySelectorAll(
        '.header--desktop .search__toggle',
      );
    }

    const [searchToggleText] =
      searchToggleButton.querySelectorAll('.visually-hidden');

    mainHeader.classList.toggle('js-active-search');

    if (mainHeader.classList.contains('js-active-search')) {
      searchToggleButton.setAttribute('aria-expanded', 'true');
      searchToggleText.innerHTML = 'Close Search';

      if (window.innerWidth < 1025) {
        setTimeout(() => {
          searchInput.focus();
        }, 300);

        searchFocusLoop(
          '.header--mobile .search__toggle',
          '.header--mobile .search__submit',
        );
      } else {
        setTimeout(() => {
          searchInput.focus();
        }, 900);

        searchFocusLoop(
          '.header--desktop .search__toggle',
          '.header--desktop .search__submit',
        );
      }
    } else {
      searchToggleButton.setAttribute('aria-expanded', 'false');
      searchToggleText.innerHTML = 'Open Search';
    }
  }

  function storeCurrentKeysPressed(e) {
    const eventType = e.type;
    const keyPressed = e.key;

    if (eventType === 'keydown') {
      if (!keysPressed.includes(keyPressed)) {
        keysPressed.push(keyPressed);
      }
    } else if (eventType === 'keyup') {
      const indexToRemove = keysPressed.indexOf(keyPressed);
      keysPressed.splice(indexToRemove, 1);
    }
  }

  function desktopMenuMouseOverNavigation(mouseEvent) {
    const hovered = mouseEvent.target;
    const activeMenuItem = hovered.closest('.header__main-item--with-children');
    const allActiveMenuItems = document.querySelectorAll(
      '.header__main-item--with-children.js-expanded',
    );

    // removes js-active class from any other menu items that could have been opened via keyboard / mouse navigation
    allActiveMenuItems.forEach((menuItem) => {
      menuItem.classList.remove('js-active');
    });

    if (activeMenuItem.classList.contains('js-disabled')) {
      activeMenuItem.classList.remove('js-disabled');
    }

    // if user hits the Escape key while hovering over a navigation item we will disable it (force the submenu to close)
    document.addEventListener('keyup', function (keyEvent) {
      if (keyEvent.key === 'Escape') {
        activeMenuItem.classList.add('js-disabled');
      }
    });
  }

  function desktopMenuMouseLeaveNavigation() {
    // when the mouse leaves a navigation item we will ensure all menu items with children have .js-disabled removed
    const disabledMenuItems = document.querySelectorAll(
      '.header__main-item--with-children.js-disabled',
    );

    if (disabledMenuItems.length) {
      disabledMenuItems.forEach((disabledMenuItem) => {
        disabledMenuItem.classList.remove('js-disabled');
      });
    }
  }

  const init = () => {
    $('.primary-nav__toggle').on('click', togglePrimaryNav);
    $('.primary-nav__submenu-toggle').on('click', togglePrimaryNavSubmenu);
    $('.header__submenu-toggle').on('click', toggleMainHeaderSubmenu);
    $(document).ready(setDesktopSubmenuHeight);
    $(window).on('resize', setDesktopSubmenuHeight);
    $(window).on('load resize', stickyHeaderScroll);
    $('.search__toggle').on('click', toggleSearch);
    $(document).on('keyup', storeCurrentKeysPressed);
    $(document).on('keydown', storeCurrentKeysPressed);
    $('.header__main-item--with-children').on(
      'mouseover',
      desktopMenuMouseOverNavigation,
    );
    $('.header__main-item--with-children').on(
      'mouseleave',
      desktopMenuMouseLeaveNavigation,
    );
  };

  return {
    init,
  };
})();

export default header;
