/**
 * Carousel display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if carousel present
 */

const carousel = (() => {
  function customSlideTransition(e) {
    const clicked = e.target;
    const slickSlider = clicked.parentElement;
    const prevBtnClicked = clicked.classList.contains('slick-prev'); // checking which button was clicked

    slickSlider.classList.add('js-transition');

    if (prevBtnClicked) {
      // transitioning to previous slide
      setTimeout(() => {
        $(slickSlider).slick('slickPrev');
      }, 300);
    } else {
      // transitioning to next slide
      setTimeout(() => {
        $(slickSlider).slick('slickNext');
      }, 300);
    }

    setTimeout(() => {
      slickSlider.classList.remove('js-transition');
    }, 600);
  }

  // resolves errors caused by slides being generated with empty IDs
  function removeID() {
    const slides = document.querySelectorAll('.slick-slide');

    slides.forEach((slide) => {
      slide.removeAttribute('id');
    });
  }

  // Render carousel
  function renderCarousel() {
    $('.carousel__wrapper').on('init', (e, slick) => {
      const [slickSlider] = slick.$slider;
      const prevBtnTemplate = document.createElement('template');
      const nextBtnTemplate = document.createElement('template');

      prevBtnTemplate.innerHTML = `<button class="slick-prev slick-arrow slick-arrow--custom" type="button"><span class="slick-sr-only">Previous</span><span class="slick-arrow__icon" aria-hidden="true"></span></button>`;
      nextBtnTemplate.innerHTML = `<button class="slick-next slick-arrow slick-arrow--custom" type="button"><span class="slick-sr-only">Next</span><span class="slick-arrow__icon" aria-hidden="true"></span></button>`;

      slickSlider.prepend(prevBtnTemplate.content);
      slickSlider.append(nextBtnTemplate.content);

      slickSlider.firstChild.addEventListener('click', customSlideTransition);
      slickSlider.lastChild.addEventListener('click', customSlideTransition);
    });

    $('.carousel__wrapper').slick({
      arrows: false,
      dots: false,
      infinite: true,
      fade: true,
      speed: 300,
      slidesToShow: 1,
      draggable: false,
    });

    removeID();
  }

  const init = () => {
    if (!$('.carousel').length) {
      return;
    }

    renderCarousel();
  };

  return {
    init,
  };
})();

export default carousel;
