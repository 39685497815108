/**
 * Image Gallery display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if Image Gallery is present
 */

const imageGallery = (() => {
  function render() {
    const $scrollableWrapper = document.querySelector('.image-gallery');
    const $galleryContainer = document.querySelector('.image-gallery__images');

    $scrollableWrapper.style.height = `${
      $galleryContainer.scrollWidth + $galleryContainer.clientWidth
    }px`;
  }

  function setHorizontalScrollPos() {
    const $scrollableWrapper = document.querySelector('.image-gallery');
    const $galleryContainer = document.querySelector('.image-gallery__images');
    const $scrollVal = -1 * $scrollableWrapper.getBoundingClientRect().top;

    $galleryContainer.scrollLeft = $scrollVal;
  }

  function galleryMenuMouseOverNavigation(mouseEvent) {
    const hovered = mouseEvent.target;
    const figure = hovered.closest('figure');

    if (figure.classList.contains('js-disabled')) {
      figure.classList.remove('js-disabled');
    }

    // if user hits the Escape key while hovering over a gallery item we will disable it (hiding figcaption / resetting z-index of hovered figure)
    document.addEventListener('keyup', (keyEvent) => {
      if (keyEvent.key === 'Escape') {
        figure.classList.add('js-disabled');
      }
    });
  }

  function galleryMenuMouseLeaveNavigation() {
    const allGalleryItems = document.querySelectorAll(
      '.image-gallery__images > figure',
    );

    // removes js-disabled class from any other gallery items that could have been disabled via keyboard / mouse navigation
    allGalleryItems.forEach((galleryItem) => {
      galleryItem.classList.remove('js-disabled');
    });
  }

  function galleryKeyboardNavigation(e) {
    const isEscapePressed = e.key === 'Escape' || e.keyCode === 27;
    const focused = document.activeElement;

    if (!focused.parentElement.classList.contains('image-gallery__images')) {
      return;
    }

    if (focused.classList.contains('js-disabled')) {
      focused.classList.remove('js-disabled');
    }

    if (isEscapePressed) {
      focused.classList.add('js-disabled');
    }
  }

  const init = () => {
    if (!$('.image-gallery').length) {
      return;
    }

    $(window).on('load resize', () => {
      render();
    });

    $(window).on('load scroll resize', () => {
      setHorizontalScrollPos();
    });

    $('.image-gallery__images > figure').on(
      'mouseover',
      galleryMenuMouseOverNavigation,
    );

    $('.image-gallery__images > figure').on(
      'mouseleave',
      galleryMenuMouseLeaveNavigation,
    );

    $(document).on('keydown', galleryKeyboardNavigation);
    $(document).on('keyup', galleryKeyboardNavigation);
  };

  return {
    init,
  };
})();

export default imageGallery;
