import lastWord from '../util/last-word';

/* eslint-disable global-require */
const facultyTable = (() => {
  require('datatables.net-dt')();
  require('datatables.net-buttons')();

  const renameLabel = (type) => {
    if (type.toUpperCase() === 'NAME & TITLE') {
      return 'type';
    }

    if (type.toUpperCase() === 'OFFICE LOCATION') {
      return 'campusLocation';
    }

    return type.toLowerCase();
  };

  const directoryInit = () => {
    const $tableHolder = $('#faculty-table');
    const ajaxPath = $tableHolder[0].dataset.url;

    function renderTable(sortedData) {
      const table = $tableHolder
        .DataTable({
          data: sortedData,
          processing: true,
          pagingType: 'simple_numbers',
          sPaginationType: 'ellipses',
          deferLoading: 57,
          ordering: false,

          language: {
            search:
              '<span class="search-title">Search Within Table</span><svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.4231 21.8462C17.1796 21.8462 21.8462 17.1796 21.8462 11.4231C21.8462 5.66657 17.1796 1 11.4231 1C5.66657 1 1 5.66657 1 11.4231C1 17.1796 5.66657 21.8462 11.4231 21.8462Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /><path d="M26.0006 26L18.7891 18.7885" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            searchPlaceholder: 'Search within table',
            infoFiltered: '',
            infoEmpty: '',
            info: `
              <span>_START_-_END_</span>
              <span>of</span>
              <span>_TOTAL_</span>
            `,
            sLengthMenu: `
              <span class="table-length-wrap">
                <span class="select__wrapper">
                  <select name="table-length" class="table-length form__control">
                    <option value="10">Show <span>10</span></option>
                    <option value="20">Show <span>20</span></option>
                    <option value="30">Show <span>30</span></option>
                    <option value="40">Show <span>40</span></option>
                    <option value="50">Show <span>50</span></option>
                    <option value="-1">All</option>
                  </select>
                </span>
                <span>per page</span>
              </span>
            `,
          },
          columns: [
            {
              data: 'type',
            },
            {
              data: 'department',
            },
            {
              data: 'email',
            },
            {
              data: 'phone',
            },
            {
              data: 'campusLocation',
            },
          ],
          columnDefs: [
            {
              targets: 0,
              data: 'type',
              render(data, type, row) {
                if (row.url) {
                  return `<div class="row-section">
                  <span class="row-label">Name & Title</span>
                  <div class="row-content">
                  <a class="link-name" href="${row.url}">${row.displayName}</a>
                  <span class="title">${row.title}</span>
                  <span class="type">${row.type}</span>
                  </div>
                  </div>`;
                }
                return `<div class="row-section">
                  <span class="row-label">Name & Title</span>
                  <div class="row-content">
                  <span class="link-name" >${row.displayName}</span>
                  <span class="title">${row.title}</span>
                  <span class="type">${row.type}</span>
                  </div>
                  </div>`;
              },
            },
            {
              targets: 1,
              data: 'department',
              render(data) {
                return `<div class="row-section"><span class="row-label">Department</span><span class="department">${data}</span></div>`;
              },
            },
            {
              targets: 2,
              data: 'email',
              render(data, type, row) {
                return row.email
                  ? `<div class="row-section"><span class="row-label">Email</span><a class="email" href="${row.email}">${data}</a></div>`
                  : '';
              },
            },
            {
              targets: 3,
              data: 'phone',
              render(data, type, row) {
                const phoneNumber = row.phone.replace(/\s+/g, '');

                return row.phone
                  ? `<div class="row-section"><span class="row-label">Phone</span><a class="tel" href="tel:${phoneNumber}">${data}</a></div>`
                  : '';
              },
            },
            {
              targets: 4,
              data: 'campusLocation',
              render(data, type, row) {
                return `<div class="row-section"><span class="row-label">Location</span><span class="campusLocation">${data}</span></div>`;
              },
            },
            {
              targets: '_all',
              orderable: false,
            },
          ],
          dom: '<"heading-table"B<"heading-table-filter"<"mobile-filter-drop"<"toolbar">rf>>><"wrapper-table"<"table-holder"t>><"footer-table"p<"table-info"li>>',
          buttons: [
            {
              text: 'Filters',
              className: 'filter-btn-drop',
              action: () => {
                const $filterHolder = $('.heading-table .heading-table-filter');
                const $filterToggle = $('.heading-table .filter-btn-drop');

                $filterToggle.toggleClass('expanded');
                $filterHolder
                  .stop()
                  .slideToggle(300)
                  .attr(
                    'aria-hidden',
                    $filterHolder.attr('aria-hidden') === 'false'
                      ? 'true'
                      : 'false',
                  );
              },
            },
          ],
          keys: true,
          responsive: true,
          initComplete() {
            this.api()
              .columns()
              // eslint-disable-next-line array-callback-return
              .every(function (i) {
                const column = this;
                const info = this.page.info();
                const toolbar = $('.toolbar');
                const holder = document.createElement('div');
                const label = document.createElement('label');
                const searchHolder = $('#faculty-table_filter');
                const select = $(
                  '<select class="form__control filter"><option value="">All</option></select>',
                );

                $(label).text(renameLabel(this.header().innerText));

                $(label)
                  .addClass('form__label')
                  .attr('for', `input-${i}`)
                  .appendTo(holder);
                select.attr('id', `input-${i}`).appendTo(holder);
                $(holder).addClass('filter-box').appendTo(toolbar);
                searchHolder.appendTo(toolbar);

                select.on('change', function () {
                  const val = $.fn.dataTable.util.escapeRegex($(this).val());

                  if (column[0][0] === 1) {
                    column
                      .search(val ? `${val}$` : val, true, true, false)
                      .draw();
                  } else {
                    column
                      .search(val ? `${val}` : val, true, true, false)
                      .draw();
                  }
                });

                column
                  .data()
                  .unique()
                  .sort()
                  .each(function (d, j) {
                    if (d.length) {
                      // ensure no empty options are being created
                      select.append(`<option value="${d}">${d}</option>`);
                    }
                  });

                select.wrap('<div class="select__wrapper"></div>');
              });

            $('.dataTables_paginate.paging_simple_numbers').attr({
              role: 'navigation',
              'aria-label': 'pagination',
            });
            $('.dataTables_paginate a.current').attr('aria-current', 'true');

            document.querySelectorAll(
              '.heading-table .filter-btn-drop',
            )[0].innerHTML += '<span class="filter-btn-drop__icon"></span>';
          },
        })
        .on('draw', function () {
          $('.dataTables_paginate a.current').attr('aria-current', 'true');
        });

      const renderBtnClear = (container) => {
        const btnClear = document.createElement('button');
        const iconClear = document.createElement('i');
        const filterHolder = $(container);
        $(btnClear).addClass('clear-table').html('<span>Reset filter</span>');
        $(iconClear).addClass('icon icon-refresh').attr('aria-hidden', 'true');
        $(iconClear).appendTo(btnClear);
        $(btnClear).insertAfter(filterHolder);
      };

      const resetFilter = () => {
        $('.clear-table').on('click', function (e) {
          e.preventDefault();
          $('.filter').prop('selectedIndex', 0);
          table.search('').columns().search('').draw();
        });
      };

      renderBtnClear('.dataTables_filter');
      resetFilter();
    }

    $.getJSON(ajaxPath, (json) => {
      const arr = json;

      // Sort array alphabetically before rendering table
      arr.data.sort(function sortResults(a, b) {
        const nameA = a.lastName.toUpperCase();
        const nameB = b.lastName.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      renderTable(arr.data);
    });
  };

  const facultyTableWatcher = () => {
    // watches for any clicks on .paginate_button to check if we should run addLastWordSpan() again
    document.addEventListener('click', (e) => {
      const clicked = e.target;

      if (clicked.classList.contains('paginate_button')) {
        lastWord.addLastWordSpan('a.link-name', 150);
      }
    });
  };

  const init = () => {
    if ($('#faculty-table').length) {
      directoryInit();
      facultyTableWatcher();
    }
  };

  return {
    init,
  };
})();

export default facultyTable;
