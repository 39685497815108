/**
 * Home Q&A display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if home Q&A is present
 */

const homeQA = (() => {
  function closeAnswer() {
    const [qa] = document.querySelectorAll('.home-qa');
    const [image] = qa.querySelectorAll('.home-qa__image img');

    const [expandedQuestionCard] = qa.querySelectorAll(
      '.question-card.js-toggled',
    );
    const [questionCardButton] = expandedQuestionCard.querySelectorAll(
      '.question-card__toggle span:not([aria-hidden])',
    );
    const [answer] = expandedQuestionCard.querySelectorAll(
      '.question-card__answer-wrapper',
    );

    $(answer).slideToggle(300);
    expandedQuestionCard.classList.remove('js-toggled');
    questionCardButton.innerHTML = 'Answer';
    image.src = image.dataset.originalSrc;
    qa.classList.remove('js-darker-overlay');
  }

  function toggleAnswer(e) {
    const [qa] = document.querySelectorAll('.home-qa');
    const [image] = qa.querySelectorAll('.home-qa__image img');
    const clicked = e.target;
    const [buttonText] = clicked.querySelectorAll('span:not([aria-hidden])');
    const questionCard = clicked.parentElement.parentElement.parentElement;
    const [answer] = questionCard.querySelectorAll(
      '.question-card__answer-wrapper',
    );

    if (
      qa.querySelectorAll('.question-card.js-toggled').length &&
      qa.querySelectorAll('.question-card.js-toggled')[0] !== questionCard
    ) {
      closeAnswer();
    }

    questionCard.classList.toggle('js-toggled');
    $(answer).slideToggle(300);

    if (questionCard.classList.contains('js-toggled')) {
      buttonText.innerHTML = 'Close';
      image.src = questionCard.dataset.heroImageSrc;
      qa.classList.add('js-darker-overlay');
    } else {
      buttonText.innerHTML = 'Answer';
      image.src = image.dataset.originalSrc;
      qa.classList.remove('js-darker-overlay');
    }
  }

  function hoverAnimationIn(e) {
    $(e.target).next().addClass('js-hovered');
  }

  function hoverAnimationOut(e) {
    $(e.target).next().removeClass('js-hovered');
  }

  const init = () => {
    if (!$('.home-qa').length) {
      return;
    }

    $('.question-card__toggle').on('click', toggleAnswer);
    $('.question-card__toggle').hover(hoverAnimationIn, hoverAnimationOut);
  };

  return {
    init,
  };
})();

export default homeQA;
