/**
 * Home Hero interactions and accessibility
 * @param {*} $
 * @returns {init}
 */

const heroHome = (() => {
  function toggleVideoPlayback(e) {
    const clicked = e.target;
    const hero = clicked.closest('.hero.hero--home');
    const [video] = hero.querySelectorAll('.hero__video');
    const [visuallyHiddenText] = clicked.querySelectorAll('.visually-hidden');

    hero.classList.toggle('js-paused');

    if (hero.classList.contains('js-paused')) {
      video.pause();

      clicked.setAttribute('aria-pressed', 'true');
      visuallyHiddenText.innerHTML = 'Play Video';
    } else {
      video.play();

      clicked.setAttribute('aria-pressed', 'false');
      visuallyHiddenText.innerHTML = 'Pause Video';
    }
  }

  const init = () => {
    if (!$('.hero--home').length) {
      return;
    }

    $('.hero__controller').on('click', toggleVideoPlayback);
  };

  return {
    init,
  };
})();

export default heroHome;
