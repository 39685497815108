/**
 * PURPOSE:
 * Binds focus event handlers to acnhors inside specified blocks
 * If focus event is triggered and js-active isn't set on parent level wrapper, add it
 * USE CASE:
 * Any element with a scroll trigger event where elements are hidden from view until scrolling.
 *
 * @param {*} $
 * @returns {init} Determine and run if accordion present
 */

const focusTriggersScrollEvent = (() => {
  const init = () => {
    const selectors = $(
      '.footer-sock, .home-qa, .gen2col, .gen3col, .genimglist, .instagram, .twitter, .stat3up, .story-display',
    );

    selectors.each((i, elem) => {
      $(elem)
        .find('a, button')
        .on('focus', () => {
          if (!$(elem).hasClass('js-active')) {
            $(elem).addClass('js-active');
          }
        });
    });
  };

  return {
    init,
  };
})();

export default focusTriggersScrollEvent;
