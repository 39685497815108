/**
 * @param {*} $
 * @returns {init} Determine and run if scroll trigger elements present
 */

const scrollTrigger = (() => {
  const init = () => {
    const selectors = $(
      '.pathway-block, .hero--with-image, blockquote.quote, .genimglist, .story-display, .home-introduction, .image-gallery, .footer-sock, .home-qa, .gen2col, .gen3col, .instagram, .twitter, .stat3up',
    );

    selectors.each((i, elem) => {
      $(window).on('scroll load', () => {
        const topofDiv = $(elem).offset().top;
        const a = $(window).scrollTop() + $(window).height() * 0.7;

        if (a > topofDiv) {
          $(elem).addClass('js-active');
        }
      });
    });
  };

  return {
    init,
  };
})();

export default scrollTrigger;
